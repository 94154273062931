import { createSlice } from '@reduxjs/toolkit'

export const CART_SLICE_NAME = 'cart'

export const cartSlice = createSlice({
  name: CART_SLICE_NAME,

  initialState: {
    products: typeof window !== 'undefined' ? JSON.parse(localStorage.getItem(CART_SLICE_NAME)) || [] : [],
  },

  reducers: {

    addProductToCart: (state, action) => {
      const { products } = state
      const quantity = Number(action.payload.quantity) || 0
      const product = products.find((p) => p.id === action.payload.id)

      if ( product ) {
        const newCart = products.map((p) =>
          p.id === product.id ? { ...product, quantity: product.quantity + quantity } : p
        )
        return { ...state, products: newCart }
      }

      state.products.push(action.payload)
    },

    updateProductInCart: (state, action) => {
      const { products } = state
      const product = products.find((p) => p.id === action.payload.id)

      if ( product ) {
        const newCart = products.map((p) =>
          p.id === product.id ? action.payload : p
        )
        return { ...state, products: newCart }
      }
    },

    removeProductFromCart: (state, action) => {
      state.products.forEach((product, index) => {
        if ( product.id === action.payload.id ) {
          state.products.splice(index, 1)
        }
      })
    },

    clearCart: (state) => {
      return { ...state, products: [] }
    }
  },
})

export const {
  addProductToCart,
  updateProductInCart,
  removeProductFromCart,
  clearCart
} = cartSlice.actions

export const selectCart = (state) => state.cart

export default cartSlice.reducer
