import React from 'react'
import store from './src/store/store'
import { Provider } from 'react-redux'

import {
  CART_SLICE_NAME
} from './src/features/cart/cartSlice'

store.subscribe(() => {
  if ( typeof window !== 'undefined' ) {
    localStorage.setItem(CART_SLICE_NAME, JSON.stringify(store.getState().cart.products))
  }
})

const Pr0vider = ({ element }) => {
  return <Provider store={store}>{element}</Provider>
}

export default Pr0vider